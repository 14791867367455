<template>
  <div class="activity">
    <div class="tableBlock">
      <el-table
        :data="tableData"
        style="width: 100%"
        :height="tableHeight"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="loading"
      >
        <!-- 申请时间 -->
        <el-table-column :label="$t('applyTime')">
          <template slot-scope="scope">{{
            scope.row.created_time | secondFormat("LLL")
          }}</template>
        </el-table-column>
        <!-- 活动名称 -->
        <el-table-column
          prop="title"
          :label="$t('activityName')"
          width="300"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <!-- 申请状态 -->
        <el-table-column :label="$t('applyStatus')">
          <template slot-scope="scope">
            <div v-if="scope.row.status == 1" class="status_box">
              <!-- 审核中 -->
              <span style="background: rgb(64, 158, 255)"></span
              ><i>{{ $t("inReview") }}</i>
            </div>
            <div v-if="scope.row.status == 2" class="status_box">
              <!-- 通过 -->
              <span style="background: rgb(82, 196, 26)"></span
              ><i>{{ $t("through") }}</i>
            </div>
            <div v-if="scope.row.status == 3" class="status_box">
              <!-- 审核未通过 -->
              <span style="background: #e6a23c"></span
              ><i>{{ $t("applyReject") }}</i>
            </div>
          </template>
        </el-table-column>
        <!-- 编辑 -->
        <el-table-column :label="$t('handle')">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status != 3"
              type="text"
              size="small"
              @click="cancel(scope.row, scope.$index)"
              >{{ $t("Cancelapplication") }}</el-button
            >
          </template>
        </el-table-column>
        <!-- <div slot="empty" style="line-height: 548px">
          <p style="color: #909399">暂无数据</p>
        </div> -->
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        layout="total,prev, pager, next"
        :current-page.sync="currentPage"
        :page-size="limit"
        :total="total"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
// 入会申请
export default {
  data() {
    return {
      tableHeight: 100,
      total: 0,
      currentPage: 1,
      limit: 10,
      loading: false,
      tableData: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    pageChange(index) {
      this.currentPage = index;
      this.getList();
    },
    async getList(scope, status) {
      this.loading = true;
      let params = {
        user_id: this.USER_INFO.id,
        limit: this.limit,
        start: this.currentPage - 1,
        apply_type: 2, //活动申请
        source: this.PJSource,
      };
      let result = await this.$store.dispatch(
        "baseConsole/getSiffaApplyList",
        params
      );
      if (result.success) {
        this.total = result.total;
        this.tableData = result.data;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    async cancel(row, index) {
      this.$confirm(this.$t("isCencelApply"), this.$t("tipsTitle"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(async () => {
          this.loading = true;
          let params = {
            user_id: this.USER_INFO.id,
            apply_type: 2, //活动申请
            source: this.PJSource,
            apply_id: row.apply_id,
          };
          let result = await this.$store.dispatch(
            "baseConsole/getSiffaApplyList",
            params
          );
          if (result.success) {
            this.loading = false;
            this.getList();
          } else {
            this.loading = false;
          }
        })
        .catch((e) => {});
    },
  },
};
</script>

<style lang="less" scoped>
.activity{
    padding-top: 12px;
}
.status_box {
  span {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 5px;
  }
}
.pagination {
  text-align: right;
  margin-top: 15px;
}
</style>